html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: 'helvetica', sans-serif;
}
#particles-js {
  width: 100%;
  height: 100%;
  background-color: #F6F6F8;
  background-image: url((unknown));
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  position: absolute;
}
.login-container {
  width: 100%;
  height: 100%;
  justify-content: center;
  display: flex;
}
.login-form {
  margin-top: calc(12vh + 36px);
  z-index: 2;
  height: fit-content;
  position: relative;
}
.login-input {
  margin-bottom: 10px;
  position: relative;
}
.login-input input {
  width: 300px;
  padding: 10px 0 10px 50px;
  border: 1px solid #3f454a33;
  outline: none;
}
.login-input i {
  position: absolute;
  top: 9px;
  font-size: 1.3rem;
  width: 40px;
  border-right: 1px solid lightgrey;
  text-align: center;
}
.login-input .login-google-logo {
  position: absolute;
  top: 7px;
  width: 30px;
  padding: 0 5px 0 8px;
  border-right: 1px solid lightgrey;
  text-align: center;
}
.login-input input.login-google-2FA-code {
  font-size: 1.2rem;
  padding-left: 25px;
  padding-right: 25px;
  text-align: center;
}
.classic-button {
  text-align: center;
  padding: 10px 0;
  background-color: #2b82bf;
  color: white;
  border: 1px solid transparent;
  font-size: 1.2rem;
  cursor: pointer;
  margin-top: 15px;
  width: 352px;
  text-decoration: none;
  display: block;
}
.classic-button:hover {
  background-color: #1d73af;
}
.logo-container {
  width: 262px;
  margin: 0 auto 30px auto;
}
.login-or {
  overflow: hidden;
  position: relative;
  line-height: 1em;
  text-align: left;
  font-size: 75%;
  color: #546e7a;
  margin-top: 20px;
}
.login-or:after {
  content: '';
  position: absolute;
  top: 0.8em;
  width: 100%;
  height: 1px;
  margin-left: 1em;
  background: #dde0e2;
}
.login-bottom {
  position: absolute;
  left: 0;
  width: 100%;
  bottom: 12px;
  z-index: 4;
}
.login-bottom .login-links {
  display: flex;
  max-width: 340px;
  justify-content: space-between;
  font-size: 0.75rem;
  align-items: center;
  margin: 0 auto;
}
.login-bottom .login-links a {
  color: #1f4e79;
}
.login-bottom .login-links i {
  font-size: 0.5rem;
  color: #274c75;
}
.login-link-back {
  margin-top: 15px;
  text-align: center;
}
.login-link-back a {
  text-decoration: none;
  color: #1f4e79;
}
.login-error {
  max-width: 300px;
  color: #ff3d3d;
  text-align: center;
  margin: 0 auto 20px auto;
}
.login-confirm {
  font-size: 1.1rem;
  margin-bottom: 1rem;
}
.login-confirm-option {
  display: flex;
  justify-content: center;
}
.link-button {
  color: #1f4e79;
  text-align: center;
  margin-top: 15px;
  background: none;
  border: none;
  width: 100%;
  text-decoration: underline;
  cursor: pointer;
}
.content-wrapper {
  display: contents;
}
.login-in-progress {
  max-width: 300px;
  color: #2e8bcc;
  text-align: center;
  margin: 12.5vh auto 0 auto;
  font-size: 125%;
  font-weight: 300 !important;
}
